export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~5],
		"/account/controllers": [6,[2]],
		"/account/controllers/add": [8,[2]],
		"/account/controllers/add/wait": [~9,[2]],
		"/account/controllers/[controller_id]": [7,[2]],
		"/account/login": [~10,[2]],
		"/account/oauth-error": [11,[2]],
		"/account/password-reset": [~12,[2]],
		"/account/register": [~13,[2]],
		"/account/reset-password": [~14,[2]],
		"/account/settings": [~15,[2]],
		"/account/settings/avatar": [16,[2]],
		"/account/settings/security": [17,[2]],
		"/admin": [~18],
		"/controller": [19],
		"/create": [~20],
		"/dashboard": [~21],
		"/dashboard/files": [22],
		"/edit": [~23],
		"/edit/files": [24],
		"/edit/videos": [~25],
		"/explore": [26],
		"/import": [~27],
		"/moderation": [28],
		"/play": [~29],
		"/practice": [30],
		"/quiztivity/create": [31],
		"/quiztivity/edit": [32],
		"/quiztivity/play": [33,[],[3]],
		"/quiztivity/share/[share_id]": [~34],
		"/remote": [35],
		"/results": [36],
		"/results/[result_id]": [37],
		"/search": [38],
		"/user/[user_id]": [39],
		"/view/[quiz_id]": [~40,[],[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';